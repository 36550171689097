import { render, staticRenderFns } from "./mobile_std_result_5.vue?vue&type=template&id=148165d6&scoped=true&"
import script from "./mobile_std_result_5.vue?vue&type=script&lang=js&"
export * from "./mobile_std_result_5.vue?vue&type=script&lang=js&"
import style0 from "./mobile_std_result_5.vue?vue&type=style&index=0&id=148165d6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148165d6",
  null
  
)

export default component.exports