<!-- 高校大类专业匹配全景报告 etc -->
<template>
  <div class="main" style="padding-bottom: 5rem;">
    <div class="print-page" id="cover" name="cover">
      <h1 class="lj-text-center">专业匹配报告（VIP版）</h1>
      <h2>简介</h2>
      <p>
        我们的研发者根据您的内在特质，利用我们独有的专利智能专业匹配算法（专利号：201810642977.1）为您提供专业匹配报告。该报告建立在我们对上万条数据的智能分析与测评基础之上，它是您可以信赖的专业匹配报告。
      </p>
      <p>
        该部分分为 3
        个报告，第一个报告是高校大类专业匹配全景报告，您与所有高校大类专业的匹配情况报告。我们利用智能算法给出了所有大学大类专业与您的匹配值，这是一个全景图，您可以了解每一个大学专业与您的匹配程度，并明智地选择更适合您的专业。我要提醒您，您要选择的专业可能在前30个匹配专业中产生。
      </p>
      <p>
        第二个报告是最适合的高校大类专业详细报告，我们会对您最适合的前15个大类专业进行详细解读，您可以了解到每个专业的培养目标，主要课程，就业方向，并了解国内有哪些大学在该专业上有优势，这方便您在做高考填报决策时进行选择。
      </p>
      <p>
        第三个报告是最适合专业的小类排序报告，我们会对最适合的15个大类专业中的小类进行排序，这也是我们研发团队通过智能匹配算法得到的，目前只有我们独家提供。这个报告非常有助于您在进行专业小类选择时使用。
      </p>
      <p>
        如果您对测评结果还有疑问，或需要进一步解释，可以点击下方按钮，请专家解读您的专业定位报告。
      </p>
      <div class="button-wrapper"></div>
      <p class="page-footer no-indent">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </p>
    </div>
    <div class="print-page" id="contents" name="contents">
      <h1>
        <a href="#contents" @click.prevent="setPage('contents')"
          >目录（点击可跳转）</a
        >
      </h1>
      <p class="no-indent">
        <a href="#category-overall" @click.prevent="setPage('category-overall')"
          >一、高校大类专业匹配全景报告</a
        >
      </p>
      <p class="no-indent">
        <a href="#category-detail" @click.prevent="setPage('category-detail')"
          >二、最适合的高校大类专业详细报告</a
        >
      </p>
      <p class="no-indent">
        <a href="#category-major" @click.prevent="setPage('category-rank')"
          >三、最适合的高校大类专业内小类专业排序报告</a
        >
      </p>
      <p class="no-indent">
        <a href="#epilogue" @click.prevent="setPage('epilogue')">结语</a>
      </p>
    </div>
    <div class="print-page" id="category-overall" name="category-overall">
      <h1>一、高校大类专业匹配全景报告</h1>
      <p>
        我们利用智能算法给出了所有大学大类专业与您的匹配值，这是一个全景图，您可以了解每一个大学专业与您的匹配程度，并明智地选择更适合您的专业。我要提醒您，您要选择的专业可能在前30个匹配专业中产生。
      </p>
      <div
        id="majorcontainer"
        style="min-height: 3000px; margin-left: auto; margin-right: auto;"
      ></div>
    </div>
    <div class="print-page" id="category-detail" name="category-detail">
      <h1>二、最适合的高校大类专业详细报告</h1>
      <p>
        在本报告中，我们会对您最适合的前15个大类专业进行详细解读，您可以了解到每个专业的培养目标，主要课程，就业方向，并了解国内有哪些大学在该专业上有优势，这方便您在做高考填报决策时进行选择。
      </p>
      <div
        class="major-detail"
        v-for="(item, index) in detailMajorsData"
        :key="item.name"
      >
        <h2>{{ index + 1 }}. {{ item.name }}</h2>
        <p>包含 {{ item.submajorsData.length }} 个专业</p>
        <div
          class="submajor-detail"
          v-for="(submajor, subindex) in item.submajorsData"
          :key="submajor.name"
        >
          <h3>({{ subindex + 1 }}) {{ submajor.majorname }}</h3>
          <p v-if="isNotEmpty(submajor.background)">
            <strong>专业背景：</strong>{{ submajor.background }}
          </p>
          <p v-if="isNotEmpty(submajor.learning_threshold)">
            <strong>学习门槛：</strong>{{ submajor.learning_threshold }}
          </p>
          <p v-if="isNotEmpty(submajor.main_courses)">
            <strong>主要课程：</strong>{{ submajor.main_courses }}
          </p>
          <p v-if="isNotEmpty(submajor.work_type)">
            <strong>毕业去向：</strong>{{ submajor.work_type }}
          </p>
          <p v-if="isNotEmpty(submajor.graduates_num_2)">
            <strong>毕业生规模（近五年平均）：</strong
            >{{ submajor.graduates_num_2 }}
          </p>
          <p v-if="isNotEmpty(submajor.school_num)">
            <strong>毕业生规模（院校数量）：</strong>{{ submajor.school_num }}
          </p>
          <p v-if="isNotEmpty(submajor.liberal_proportion)">
            <strong>文理科比例（文科）：</strong
            >{{ submajor.liberal_proportion }}%
          </p>
          <p v-if="isNotEmpty(submajor.science_proportion)">
            <strong>文理科比例（理科）：</strong
            >{{ submajor.science_proportion }}%
          </p>
          <p v-if="isNotEmpty(submajor.employment_num)">
            <strong>就业统计（近五年平均）：</strong
            >{{ submajor.employment_num }}
          </p>
          <p v-if="isNotEmpty(submajor.special_colleges_1)">
            <strong>列入特色专业院校（特色院校）：</strong
            >{{ submajor.special_colleges_1 }}
          </p>
          <p v-if="isNotEmpty(submajor.special_colleges_2)">
            <strong>列入特色专业院校（全国重点）：</strong
            >{{ submajor.special_colleges_2 }}
          </p>
          <p v-if="isNotEmpty(submajor.special_colleges_3)">
            <strong>列入特色专业院校（省属重点）：</strong
            >{{ submajor.special_colleges_3 }}
          </p>
          <p v-if="isNotEmpty(submajor.special_colleges_4)">
            <strong>列入特色专业院校（普通本科）：</strong
            >{{ submajor.special_colleges_4 }}
          </p>
          <p v-if="isNotEmpty(submajor.other_colleges_1)">
            <strong>其他开设院校1（全国重点）：</strong
            >{{ submajor.other_colleges_1 }}
          </p>
          <p v-if="isNotEmpty(submajor.other_colleges_2)">
            <strong>其他开设院校2（省属重点）：</strong
            >{{ submajor.other_colleges_2 }}
          </p>
          <p v-if="isNotEmpty(submajor.other_colleges_3)">
            <strong>其他开设院校3（一般本科）：</strong
            >{{ submajor.other_colleges_3 }}
          </p>
          <p v-if="isNotEmpty(submajor.others)">
            <strong>其他：</strong>{{ submajor.others }}
          </p>
        </div>
      </div>
      <p class="page-footer no-indent">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </p>
    </div>
    <div class="print-page" id="category-major" name="category-major">
      <h1>三、最适合的高校大类专业内小类专业排序报告</h1>
      <p>
        本报告是对最最适合的15个大类专业中的小类进行排序，这也是我们研发团队通过智能匹配算法得到的，目前只有我们独家提供。这个报告非常有助于您在进行专业小类选择时使用。
      </p>
      <div
        class="main-part"
        v-for="(major, index) in majorsData"
        :key="major.code"
      >
        <div class="part-title">{{ index + 1 }}. {{ major.name }}</div>
        <div class="part-content">
          <div
            class="submajor-wrapper"
            v-for="(submajor, index) in major.submajors"
            :key="submajor.code"
          >
            <div class="submajor">
              <div class="submajor-number">{{ index + 1 }}</div>
              <div class="submajor-title">{{ submajor.majorname }}</div>
            </div>
          </div>
        </div>
      </div>
      <p class="page-footer no-indent">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </p>
    </div>
    <div class="print-page" id="epilogue" name="epilogue">
      <h1>结语</h1>
      <p>
        非常感谢你选择灯塔教育专业测评系统，希望我们的测评报告能帮助你清晰地认识自我，为你的高考专业选择和职业生涯发展提供有效的帮助。
      </p>
      <p>
        一个人的职业倾向和职业兴趣，没有绝对的好坏之分。但不同的特点对于不同的专业选择会存在“合适”和“不合适”的区别，本测评是用于区分不同个体的不同职业兴趣和倾向类型，不同的类型在某个维度上的得分高低，都不能认为个体在这个方面具有不良属性或者缺陷。一个人的职业倾向决定了最适合他的职业，当然，对某一职业感兴趣，并不代表适合这个职业，还必须具备与该职业相匹配的能力。
      </p>
      <p>
        本报告是科学的参考资料，但不是唯一的决策依据，其目的是为了帮助你拓宽思路，接受更多的可能，而并非限制你的选择。实际上，我们在报专业或选择职业时，首先应该对我们自身兴趣、性格、能力、价值观进行全面深入的了解。这次测试的目的，就是为了帮助你更加准确、更加全面的了解你的职业倾向，为专业选择和生涯决策提供参考。此外，虽然职业兴趣具有相对稳定性，但仍然是可变的。因而不能用一次测验的结果作为永久衡量其人格因素指标。比较妥当的操作办法是每六个月做一次测评，了解自己人格因素的变化。便于及时调整。
      </p>
      <p>
        每个人的能力、个性或者行为方式都是非常复杂的，不管是多好的测验，仅仅靠一套测验数据来解释或者说归纳人格特点总会显得单薄，甚至您会觉得有些地方与您自己的认知相悖。如果出现这种情况，可能有如下几种原因：您是否根据自己的第一反应做出的回答？您是否受到自己应该选择什么或者别人希望您选择什么的影响？为了使您的测验结果得到最为恰当的解释，您可进一步向有关专业人士咨询。
      </p>
      <p>最后，祝您考入理想的大学，选择适合的专业！</p>
      <div class="button-wrapper">
        <a
          class="lj-btn lj-btn-secondary"
          @click="jumpPage('reportCenter')"
          style="margin-right: 1rem;"
          >返回测评导航</a
        >
      </div>
    </div>
    <div
      class="action-wrapper"
      :class="{ 'action-wrapper-display': actionWrapperDisplay }"
    >
      <div class="btn-wrapper">
        <button
          class="lj-btn lj-btn-sm lj-btn-third action-prev"
          id="action-prev"
          @click="jumpToPrevSection"
        >
          上一节</button
        ><button
          class="lj-btn lj-btn-sm lj-btn-default action-contents"
          id="action-contents"
          @click="jumpToContents"
        >
          返回目录</button
        ><button
          class="lj-btn lj-btn-sm lj-btn-primary action-next"
          id="action-next"
          @click="jumpToNextSection"
        >
          下一节
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import './../../../../Public/utils/new-index/js/jarallax'
import './../../../../Public/utils/new-index/js/jquery.totemticker'
import './../../../../Public/utils/new-index/js/move-top'
import './../../../../Public/utils/new-index/js/easing'
import $ from 'jquery'

import {
  queryHolland,
  queryMajorDone,
  queryMBIT,
  querySubject,
  queryMatchingVal,
  queryMatchingMajors,
  queryMatchingMajorsDetails
} from './../../../../api/major'

import Highcharts from './../../../../Public/utils/highcharts/highcharts'

$(document).ready(function ($) {
  $('.scroll').click(function (event) {
    event.preventDefault()
    $('html,body').animate(
      {
        scrollTop: $(this.hash).offset().top
      },
      1000
    )
  })
})

$(document).ready(function () {
  $().UItoTop({
    easingType: 'easeOutQuart'
  })
})
export default {
  data: function () {
    return {
      matchingValue: [76, 23],
      major: ['计算机', '金融'],
      questionaireDone: true,
      chart: {},
      detailMajorsData: [],
      majorsData: [],
      actionWrapperDisplay: !1,
      ids: [
        'epilogue',
        'category-major',
        'category-detail',
        'category-overall',
        'contents',
        'cover',
      ]
    }
  },
  computed: {
    majorchartOptions: function () {
      return {
        chart: { type: 'bar' },
        xAxis: {
          categories: this.major,
          title: {
            text: '专业类',
            style: { fontSize: '16px' }
          },
          labels: {
            step: 1,
            staggerLines: 1,
            style: { fontSize: '14px' }
          }
        },
        title: { text: null },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '匹配度',
            align: 'high',
            style: { fontSize: '16px' }
          },
          labels: { format: '{value} %' }
        },
        legend: { enabled: !1 },
        tooltip: { valueDecimals: 2, valueSuffix: ' %' },
        plotOptions: {
          bar: {
            dataLabels: { enabled: !0, format: '{y} %' }
          },
          series: { pointWidth: 30 }
        },
        credits: { enabled: !1 },
        series: [{ name: '匹配度', data: this.matchingValue }]
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    setPage: function () {
      var t = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : 0
      'string' == typeof t && (t = this.ids.indexOf(t)),
        t < 0 && (t = 0),
        window.scroll(
          0,
          this.getElementTop(document.getElementById(this.ids[t])) - 40
        )
    },
    getCurrentPage: function () {
      for (var t = 0; t < this.ids.length; t++) {
        var e = this.ids[t]
        if (
          this.getWindowScrollY() + 50 >
          this.getElementTop(document.getElementById(e))
        )
          return {
            index: t,
            page: this.ids.length - t - 1,
            id: e,
            element: document.getElementById(e)
          }
      }
    },
    getElementTop: function (t) {
      for (var e = t.offsetTop, a = t.offsetParent; null !== a; )
        (e += a.offsetTop), (a = a.offsetParent)
      return e
    },
    getWindowScrollY: function () {
      var t = void 0 !== window.pageXOffset,
        e = 'CSS1Compat' === (document.compatMode || '')
      return t
        ? window.pageYOffset
        : e
        ? document.documentElement.scrollTop
        : document.body.scrollTop
    },
    jumpToContents: function () {
      this.setPage('contents')
    },
    jumpToPrevSection: function () {
      var t = this.getCurrentPage().index
      console.log(this.getCurrentPage());
      t < this.ids.length - 1 && this.setPage(t+1)
    },
    jumpToNextSection: function () {
      var t = this.getCurrentPage().index
      0 < t && this.setPage(t-1)
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    },

    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },

    majorInitChart () {
      this.chart = new Highcharts.Chart(
        document.getElementById('majorcontainer'),
        this.majorchartOptions
      )
    },

    isNotEmpty: function (l) {
      return '' !== l && '-' !== l && '—' !== l && '－' !== l && undefined !==l && null !==l
    },
  },
  mounted: function () {
    if (this.isMobile === null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage2'
      })
      return
    }
    let that = this
    window.onscroll = function () {
      var t = that.getWindowScrollY()
      that.actionWrapperDisplay =
        t + 50 > that.getElementTop(document.getElementById('contents'))
    }
    queryMatchingVal({}).then(res => {
      if (res.rescode == 200) {
        console.log('queryMatchingVal', res)
        this.matchingValue = res.data.matchingValue
        this.major = res.data.major
        this.majorInitChart()
      }
    })

    queryMatchingMajorsDetails({}).then(res => {
      if (res.rescode == 200) {
        console.log('queryMatchingMajorsDetails', res)
        this.detailMajorsData = res.majorsData
      }
    })
    queryMatchingMajors({}).then(res => {
      if (res.rescode == 200) {
        console.log('queryMatchingMajors', res)
        this.majorsData = res.majorsData
      }
    })
  }
}
</script>

<style lang="less" scoped>
.mbti-describe {
  text-align: left;
}
@import url('./../../../../Public/resources/css/templates/home/major/std_mobile_result.min.css');
@import url('./../../../../Public/utils/han/han.css');
</style>
